<template>
  <!-- <transition name="fade" mode="out-in"> -->
  <div class="screen-wrap">
    <v-app class="hmc-app">
      <v-main class="main-body">
        <v-container class="app-body">
          <!-- BOC:[error] -->
          <ApiErrorDialog v-if="api.isError" :api="api" />
          <!-- EOC -->
          <div class="pe-2 ps-3 app-title text-h5">
            {{ $t("string.league_reward") }}
            <v-spacer></v-spacer>
            <CloseBtn size="40" :callback="exit" />
          </div>
          <div class="pa-2">
            <div v-for="(item, i) in items" :key="i" class="pb-6">
              <div class="d-flex align-center">
                <v-img
                  max-width="100"
                  :src="item.imageUrl"
                  contain
                  class="me-3"
                >
                </v-img>
                <div class="text-stroke-sm text-h5">
                  {{ item.name }} - {{ item.tier }}
                </div>
              </div>
              <div class="list-panel">
                <div
                  v-for="(league, i) in item.LeagueGroup"
                  :key="i"
                  class="list-card"
                >
                  <div class="d-flex align-center">
                    {{ league.start }} - {{ league.end }}
                  </div>
                  <div class="d-flex align-center">
                    <div class="d-flex">
                      <div
                        class="coin-box"
                        v-for="(reward, i) in league.LeagueGroupReward"
                        :key="i"
                      >
                        <v-img
                          max-width="30"
                          :src="JSON.parse(reward.images).icon.url"
                          class="mx-auto d-flex align-center justify-center coin-img"
                        >
                        </v-img>
                        +{{ reward.quantity }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-container>
      </v-main>
    </v-app>
  </div>
  <!-- </transition> -->
</template>
    
    <script>
import { mapState } from "vuex";
export default {
  components: {
    CloseBtn: () =>
      import(
        /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn"
      ),
    ApiErrorDialog: () =>
      import(
        /* webpackChunkName: "component-api-error-dialog" */ "@/components/ApiErrorDialog.vue"
      ),
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },

    items: [],
  }),
  created() {
    this.api.url =
      this.$api.servers.event +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/event/league";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };

    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.items = resp;
      this.changeLeague();
    };
    this.api.method = "GET";
  },
  mounted() {
    this.$api.fetch(this.api);
  },
  methods: {
    exit() {
      this.$router.push({
        name: "PageEventRank",
      });
    },
  },
};
</script>

    <style scoped>
.app-title {
  z-index: 99;
  position: fixed;
  top: 0;
  max-width: 480px !important;
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
  box-shadow: 0px 5px 2px rgba(128, 128, 128, 0.5) !important;
  border: #caad87 5px solid;
  margin: auto;
  color: #fff;
  background-color: #f7efe3;
  text-shadow: #cbb18f 3px 0px 0px, #cbb18f 2.83487px 0.981584px 0px,
    #cbb18f 2.35766px 1.85511px 0px, #cbb18f 1.62091px 2.52441px 0px,
    #cbb18f 0.705713px 2.91581px 0px, #cbb18f -0.287171px 2.98622px 0px,
    #cbb18f -1.24844px 2.72789px 0px, #cbb18f -2.07227px 2.16926px 0px,
    #cbb18f -2.66798px 1.37182px 0px, #cbb18f -2.96998px 0.42336px 0px,
    #cbb18f -2.94502px -0.571704px 0px, #cbb18f -2.59586px -1.50383px 0px,
    #cbb18f -1.96093px -2.27041px 0px, #cbb18f -1.11013px -2.78704px 0px,
    #cbb18f -0.137119px -2.99686px 0px, #cbb18f 0.850987px -2.87677px 0px,
    #cbb18f 1.74541px -2.43999px 0px, #cbb18f 2.44769px -1.73459px 0px,
    #cbb18f 2.88051px -0.838247px 0px;
}
.text-stroke-sm {
  color: #ffdd1f;
  text-shadow: #000 3px 0px 0px, #000 2.83487px 0.981584px 0px,
    #000 2.35766px 1.85511px 0px, #000 1.62091px 2.52441px 0px,
    #000 0.705713px 2.91581px 0px, #000 -0.287171px 2.98622px 0px,
    #000 -1.24844px 2.72789px 0px, #000 -2.07227px 2.16926px 0px,
    #000 -2.66798px 1.37182px 0px, #000 -2.96998px 0.42336px 0px,
    #000 -2.94502px -0.571704px 0px, #000 -2.59586px -1.50383px 0px,
    #000 -1.96093px -2.27041px 0px, #000 -1.11013px -2.78704px 0px,
    #000 -0.137119px -2.99686px 0px, #000 0.850987px -2.87677px 0px,
    #000 1.74541px -2.43999px 0px, #000 2.44769px -1.73459px 0px,
    #000 2.88051px -0.838247px 0px;
}

.app-body {
  max-width: 480px;
  min-height: 100%;
  padding: 80px 0 40px 0;
  /* background-color: #f5dfc5; */
}

.main-body {
  /* background-color: #f5dfc5; */
  background-color: rgba(214, 132, 49, 0.3);
}

.list-card {
  padding: 10px 10px 10px 20px;
  margin: 10px;
  background-color: #fff2e2;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.coin-box {
  line-height: 12px;
  background-color: #fff;
  border: #292218 solid 2px;
  margin: 0 3px;
  font-size: x-small;
  text-align: center;
}

.coin-img {
  background-color: #6b583c;
}
</style>